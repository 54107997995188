import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  Bold,
  SelectAll,
  Essentials,
  Clipboard,
  FindAndReplace,
  Highlight,
  CodeBlock,
  Heading,
  TableCaption,
  Alignment,
  SourceEditing,
  TableColumnResize,
  Undo,
  Image,
  ImageUpload,
  ImageTextAlternative,
  ImageCaption,
  ImageResize,
  ImageToolbar,
  ImageStyle,
  ImageResizeEditing,
  ImageResizeButtons,
  TableToolbar,
  SimpleUploadAdapter,
  HorizontalLine,
  Mention,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Font,
  TableProperties,
  TableCellProperties
} from '../../../../node_modules/ckeditor5';

@Injectable({
  providedIn: 'root',
})
export class CkEditorConfigService {
  apiBaseUrl: any = environment.apiBaseUrl;
  mediaBaseUrl: any = environment.mediaBaseUrl;
  constructor() {}
  getConfig() {
    const token = `${'Bearer ' + localStorage.getItem('token')}`;
    //=------------------------development--------------------//
    return {
      toolbar: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic','alignment', '|',
        'link','horizontalLine', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|', 
        'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor','|',
        'highLight','sourceEditing','selectAll','codeBlock','findAndReplace','|',
        'imageUpload', 'imageStyle:alignLeft', 'imageStyle:side','imageStyle:inline', 
        'imageStyle:block', 'imageStyle:alignBlockLeft', 'imageStyle:alignBlockRight',
        'resizeImage','toggleImageCaption', 'imageTextAlternative','|', 
        'insertTable','tableColumn', 'tableRow', 'mergeTableCells','toggleTableCaption','tableProperties', 'tableCellProperties'
      ],
      image: {
        resizeOptions: [
        {
            name: 'resizeImage:original',
            value: null,
            icon: 'original'
        },
        {
            name: 'resizeImage:custom',
            value: 'custom',
            icon: 'custom'
        },
        {
            name: 'resizeImage:50',
            value: '50',
            icon: 'medium'
        },
        {
            name: 'resizeImage:75',
            value: '75',
            icon: 'large'
        }
    ]
  },
      table: {
        // contentToolbar: [
        //     'toggleTableCaption'
        // ]
    },
      simpleUpload: {
        uploadUrl: `${this.apiBaseUrl}/api/media/from-editor`,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: token,
        },
      },
      placeholder: 'Type the content here!',
      plugins: [
        Bold,
        SelectAll,
        Essentials,
        Clipboard,
        FindAndReplace,
        Highlight,
        CodeBlock,
        ImageResizeEditing, ImageResizeButtons, ImageToolbar,
        Heading,
        TableCaption,
        Alignment,
        SourceEditing,
        ImageResize,
        ImageCaption,
        TableColumnResize,
        Undo, 
        Image, 
        TableToolbar,
        ImageUpload, 
        ImageTextAlternative,
        HorizontalLine,
        Mention,
        Indent,
        ImageStyle,
        IndentBlock,
        Italic,
        Link,
        List,
        MediaEmbed,
        Paragraph,
        Table,
        TableToolbar,
        TableProperties,
        TableCellProperties,
        Undo,
        Font,
        SimpleUploadAdapter
      ]
    };
  }
}
