import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpRequestService } from 'src/app/core/services';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CkEditorConfigService } from 'src/app/core/services/ck-editor-config.service';
import { ClassicEditor } from '../../../../../node_modules/ckeditor5';

@Component({
  selector: 'app-add-update-plan',
  templateUrl: './add-update-plan.component.html',
  styleUrls: ['./add-update-plan.component.scss'],
})
export class AddUpdatePlanComponent implements OnInit {
  addUpdatePlanForm: FormGroup;
  idForUpdate: string;
  buttonLoading = false;
  checkAddPermission = false;
  checkUpdatePermission = false;
  searchExam: Subject<any> = new Subject<any>();
  searchExamCategory: Subject<any> = new Subject<any>();
  examSearch = '';
  search = '';
  examCategorySearch = '';
  allExams: any[] = [];
  allExamCategories: any[] = [];
  allTestSeries: any[] = [];
  filterTestSeries: any;
  selectedTestSeries: any[] = [];
  pageIndex = 1;
  pageSize = 100;
  totalDataCount: number = 0;
  loading: boolean = false;
  limit: boolean = false;
  newSelectedTestSeries: any[] = [];
  isUnlimited: boolean = false;
  finalData: any;
  public ckeConfig: any;
  public Editor = ClassicEditor;
  tabIndex: number = 0
  constructor(
    private fb: FormBuilder,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ckEditorService: CkEditorConfigService,
  ) {
    this.tabIndex = this.activatedRoute.snapshot.queryParams.index;
    this.idForUpdate = this.activatedRoute.snapshot.params.id;
    this.addUpdatePlanForm = this.fb.group({
      name: [null, [Validators.required]],
      price: [null, [Validators.required]],
      duration: [7, [Validators.required]],
      description: [null],
      max: [null],
      isUnlimited: [false],
      status: [true],
      testSeries: [[]],
      exam: [null],
      examCategory: [null],
    });
  }
  onChange(data: any){

  }
  // on page load functions
  ngOnInit(): void {
    this.ckeConfig = this.ckEditorService.getConfig()
    this.searchExam.pipe(debounceTime(1000)).subscribe((success: any) => {
      this.examSearch = success;
      this.getAllExams();
    });
    this.searchExamCategory
      .pipe(debounceTime(1000))
      .subscribe((success: any) => {
        this.examCategorySearch = success;
        this.getAllExamsCategory();
      });
    this.getAllTestSeries();
    this.getAllExams();
    this.getAllExamsCategory();
    if (this.idForUpdate) {
      this.getPlanById();
    }
  }
  onChangeExam(value: string, skip = 0): void {
    this.getAllTestSeries();
  }
  onChangeExamCategory(value: string, skip = 0): void {
    this.getAllTestSeries();
  }
  /* Get all exam */
  getAllExams(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved'
    };
    if (this.examSearch) {
      params.search = this.examSearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exams', params).subscribe(
      (result: any) => {
        this.allExams = result.data;
      },
      (err: any) => {}
    );
  }
  /* Get all exam-category */
  getAllExamsCategory(): void {
    const params: any = {
      skip: 0,
      limit: 100,
      status: true,
      approveStatus: 'approved'
    };
    if (this.examCategorySearch) {
      params.search = this.examCategorySearch;
    } else {
      delete params.search;
    }
    this.httpRequestService.request('get', 'exam-categories', params).subscribe(
      (result: any) => {
        this.allExamCategories = result.data;
      },
      (err: any) => {}
    );
  }
  // get all Test-series
  getAllTestSeries(skip = 0, sortBy?: any): void {
    this.allTestSeries = [];
    let params: any;
    params = { skip, limit: this.pageSize, status: true };
    if (this.addUpdatePlanForm.value.examCategory) {
      params['examCategory'] = this.addUpdatePlanForm.value.examCategory;
    } else {
      delete params.examCategory;
    }
    if (this.addUpdatePlanForm.value.exam) {
      params['exam'] = this.addUpdatePlanForm.value.exam;
    } else {
      delete params.exam;
    }

    if (this.search) {
      params.search = this.search;
    }
    if (sortBy) {
      params.sortBy = JSON.stringify(sortBy);
    }
    this.loading = true;
    this.httpRequestService.request('get', 'test-series', params).subscribe(
      (result) => {
        this.loading = false;
        this.allTestSeries.push(...result.data);
        // unique test series
        this.allTestSeries = this.allTestSeries.filter(
          (thing: any, index: number, self: any) =>
            index === self.findIndex((t: any) => t._id === thing._id)
        );
        this.totalDataCount = result.totalCount;
        if (this.allTestSeries.length === this.totalDataCount) {
          this.limit = true;
        }
        this.filterSectionValues();
      },
      (err) => {
        this.loading = false;
      }
    );
  }
  // Question remove
  removeTestSeries(): void {
    const removedItems = this.selectedTestSeries.filter((x) => x.isChecked);
    let allItemsToBeRemoved: any[] = [...removedItems];
    this.selectedTestSeries = this.selectedTestSeries.filter(
      (item: any) =>
        !allItemsToBeRemoved.map((rmItem: any) => rmItem._id).includes(item._id)
    );
    this.newSelectedTestSeries = [...this.selectedTestSeries];
    this.filterSectionValues();
  }
  filterSectionValues() {
    this.filterTestSeries = this.allTestSeries
      .filter((item: any) => {
        return !this.selectedTestSeries
          .map((q: any) => q._id)
          .includes(item._id);
      })
      .map((item: any) => ({ ...item, isChecked: false }));
    // this.paragraphFilterData = this.allParagraphQuestions.filter(
    //   (item: any) => !this.selectedTestSeries.map((q: any) => q.paragraph).filter((x:any) => x).includes(item.paragraph['_id'])
    // ).map((item: any) => ({ ...item, isChecked: false}))
  }
  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.selectedTestSeries,
      event.previousIndex,
      event.currentIndex
    );
    this.newSelectedTestSeries = [...this.selectedTestSeries];
  }
  // Add  TestSeries
  addTestSeries(): void {
    this.selectedTestSeries = [
      ...this.selectedTestSeries,
      ...this.filterTestSeries
        .filter((x: any) => x.isChecked)
        .map((x: any, index: number) => ({
          ...x,
          isChecked: false,
          questionType: 'NORMAL',
          key: index,
        })),
    ];
    this.newSelectedTestSeries = [...this.selectedTestSeries];
    this.filterSectionValues();
  }

  /* Get single subject details by Id */
  getPlanById(): void {
    this.httpRequestService
      .request('get', `plans/${this.idForUpdate}`)
      .subscribe(
        (result: any) => {
          const planData = result.data
          this.addUpdatePlanForm.patchValue({
            name: planData.name,
            description: planData.description,
            price: planData.price,
            max: planData.max,
            duration: planData.duration,
            status: planData.status,
            testSeries: planData.testSeries ,
            exam: planData.exam,
            isUnlimited: planData.isUnlimited,
            examCategory: planData.examCategory,
          });
          if (planData.testSeries) {
            this.selectedTestSeries = [...planData.testSeries];
          }
          this.newSelectedTestSeries = [...this.selectedTestSeries];
        },
        (error: any) => {}
      );
  }

  clickChange(data: any) {
    this.isUnlimited = data
  }
  /* Submit subject form */
  submit(): void {
    
    if (!this.addUpdatePlanForm.valid) {
      this.markFormGroupTouched(this.addUpdatePlanForm);
    } else {
      if (this.idForUpdate) {
        this.addOrUpdatePlan(
          'put',
          `plans/${this.idForUpdate}`,
          'Plan Successfully Updated'
        );
      } else {
        this.addOrUpdatePlan('post', 'plans', 'Plan Added Successfully ');
      }
    }
  }

  /* Add Or Edit Subject */
  addOrUpdatePlan(
    requestMethod: string,
    requestURL: string,
    successMessage: string
  ): void {

    this.finalData = {
      ...this.addUpdatePlanForm.value,
      testSeries: this.newSelectedTestSeries.map((x: any) => x._id),
    };
    if(this.finalData.isUnlimited){
      this.addUpdatePlanForm.value.max = null;
      this.finalData.max = null;
      this.finalData.testSeries = null;
    }
    if (!this.finalData.isUnlimited) {
      if (!this.finalData.testSeries.length) {
        this.notificationService.error('', 'please Add Some test Series');
        return
      }
      if (!this.finalData.max) {
        this.notificationService.error(
          '',
          'Please add maximum choose of Test Series'
        );
        return
      }
    }
    this.buttonLoading = true;
    this.httpRequestService
      .request(requestMethod, requestURL, this.finalData)
      .subscribe(
        (result: any) => {
          this.notificationService.success('', successMessage);
          this.router.navigate(['/main/plan'], { queryParams: {index: this.tabIndex } });
          this.buttonLoading = false;
        },
        (error: any) => {
          if (error.error.errors) {
            const allErrors: string[] = Object.values(error.error.errors);
            for (const err of allErrors) {
              this.notificationService.error('', err);
            }
          } else {
            this.notificationService.error('', error.error.message);
          }
          this.buttonLoading = false;
        }
      );
  }
  /* For Pagination / Sending skip */
  testPagination(): void {
    this.pageIndex = this.pageIndex + 1;
    this.getAllTestSeries(this.pageSize * (this.pageIndex - 1));
  }
  /* Make All Form Controls Dirty */
  private markFormGroupTouched(formGroup: FormGroup): void {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }
  // seach Concept
  searchExamForDropdown(event: any): void {
    this.searchExam.next(event);
  }
  searchExamCategoryForDropdown(event: any): void {
    this.searchExamCategory.next(event);
  }
}
